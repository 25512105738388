import React, {useState, useEffect} from 'react';
import PhotoInterpolation, {PhotoMapping, photoMappingFromMap} from "./PhotoInterpolation";
import {Tabs, Tab, Box, Button, Alert, Typography} from '@mui/material'; // Import Alert from Material-UI

type Photo = [Uint8Array, number, number];

interface MappingCalculatorProps {
    imgdata1: Photo | null;
    imgdata2: Photo | null;
}

const MappingCalculator: React.FC<MappingCalculatorProps> = ({imgdata1, imgdata2}) => {
    const [result, setResult] = useState(null);
    const [worker, setWorker] = useState<Worker | null>(null);
    const [state, setState] = useState<string | null>(null);
    const [photoMappings, setPhotoMappings] = useState<PhotoMapping[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0); // State to track the active tab
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error messages
    const [x3d, setX3d] = useState<string | null>(null);

    useEffect(() => {
        // Create a new web worker
        const myWorker: Worker = new Worker('worker.js', {type: 'module'});

        // Set up event listener for messages from the worker
        myWorker.onmessage = function (event) {
            setResult(event.data);
            const {state, pm, x3d: x3dData} = event.data;

            if (state) {
                setState(state);

                if (state === "done" && x3dData && pm) {
                    const photoMapping = photoMappingFromMap(pm);
                    const {imgData, width, height} = photoMapping.photo1;

                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');

                    if (ctx) {
                        const imgDataObj = ctx.createImageData(width, height);
                        imgDataObj.data.set(imgData);
                        ctx.putImageData(imgDataObj, 0, 0);

                        const maxSide = Math.max(width, height);
                        const scale = 1024 / maxSide;
                        const scaledWidth = width * scale;
                        const scaledHeight = height * scale;

                        const scaledCanvas = document.createElement('canvas');
                        scaledCanvas.width = scaledWidth;
                        scaledCanvas.height = scaledHeight;
                        const scaledCtx = scaledCanvas.getContext('2d');

                        if (scaledCtx) {
                            scaledCtx.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);
                            const dataUrl = scaledCanvas.toDataURL('image/jpeg');
                            setX3d(x3dData.replace("[photo_placeholder]", dataUrl));
                        }
                    }

                    setTimeout(() => {
                        eval("x3dom.reload()");
                        document.getElementById("x3d_container")?.scrollIntoView();
                    }, 1000);
                }
            }

            if (pm) {
                const newMapping = photoMappingFromMap(pm);
                setPhotoMappings(prevMappings => {
                    const updatedMappings = [...prevMappings, newMapping];
                    setActiveTab(updatedMappings.length - 1);
                    return updatedMappings;
                });
                document.getElementById("tabView")?.scrollIntoView();
            }
        };

        myWorker.onerror = function (error) {
            console.error('Worker error:', error);
        };

        // Save the worker instance to state
        setWorker(myWorker);

        // Clean up the worker when the component unmounts
        return () => {
            myWorker.terminate();
        };
    }, []); // Run this effect only once when the component mounts

    const handleClick = () => {
        // Reset any previous error message
        setErrorMessage(null);

        // Check if both images are selected
        if (imgdata1 == null || imgdata2 == null) {
            setErrorMessage("Please select two images before calculating.");
            return;
        }
        setX3d(null); // Clear previous x3d model
        const [data1, width1, height1] = imgdata1;
        const [data2, width2, height2] = imgdata2;


        console.log("w/h " + width1 + " " + height1 + " " + width2 + " " + height2);
        // Check if images have the same dimensions
        if (width1 !== width2 || height1 !== height2) {
            setErrorMessage("The two photos must have the same width and height");
            return;
        }

        // Proceed with calculation if no errors
        if (worker) {
            setPhotoMappings([]); // Clear previous mappings
            worker.postMessage({
                name: "create",
                imgdata1: data1,
                imgdata2: data2,
                width: width1,
                height: height1,
            });
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <div id="tabView">
            {/* Display error message if exists */}
            {errorMessage && (
                <Alert severity="error" onClose={() => setErrorMessage(null)} sx={{mb: 2}}>
                    {errorMessage}
                </Alert>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
                <Button variant="contained" color="success" onClick={handleClick}
                        sx={{
                            padding: '12px 24px',
                            fontSize: '1.5rem',
                        }}
                >
                    Start calculating photo mapping
                </Button>
            </Box>

            {/* Tabs to display each PhotoInterpolation */}
            {photoMappings.length > 0 && (
                <>
                    <Box sx={{borderBottom: 1, borderColor: 'divider', marginTop: 2}}>
                        <Tabs value={activeTab} onChange={handleTabChange} aria-label="photo interpolation tabs">
                            {photoMappings.map((_, index) => (
                                <Tab key={index} label={`Iteration ${index + 1}`}/>
                            ))}
                        </Tabs>
                    </Box>

                    {/* Display the currently selected PhotoInterpolation */}
                    {photoMappings.map((photoMapping, index) => (
                        <div key={index} style={{display: activeTab === index ? 'block' : 'none'}}>
                            <PhotoInterpolation photoMapping={photoMapping}/>
                        </div>
                    ))}
                </>
            )}

            {/* Display the x3d model */}
            {x3d && (
                <div id="x3d_container" style={{
                    width: "1000px",
                    height: "1100px",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center', // Center horizontally
                    margin: '0 auto', // Center horizontally
                    border: '1px solid white'
                }}>
                    <Typography variant="h5" color="textSecondary">
                        Click and drag to rotate the 3D model.
                    </Typography>
                    <br/>
                    <Typography variant="body2" color="textSecondary">
                        Note: The 3D model is probalbly distorted and is not suitable for measurements.
                    </Typography>

                    <div dangerouslySetInnerHTML={{__html: x3d}}/>
                </div>
            )}
        </div>
    );
};

export default MappingCalculator;
